import Typed from 'typed.js';

;(function(window, document, $) {
	var $win = $(window);
	var $doc = $(document);

	var options = {
		strings: ['$ rdimitrov.dev - coming soon!'],
		typeSpeed: 70,
		loop: true,
		backDelay: 2000,
		backSpeed: 10
	};

	var typed = new Typed('.command-prompt__entry-typed', options);
})(window, document, window.jQuery);
